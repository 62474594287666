<template>
    <NavigationBar />
    <ArticleList />
    <BlogFooter/>
</template>


<script>
import BlogFooter from '@/components/BlogFooter.vue'
import ArticleList from '@/components/ArticleList.vue'
import NavigationBar from "@/views/NavigationBar.vue"

export default {
    name: 'myHome',
    components: { NavigationBar, BlogFooter, ArticleList }
}
</script>

<style>
* {
    margin: 0;
    padding: 0;
}
</style>
<!-- 用来存放现在及将来所有的页面文件 -->