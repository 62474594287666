import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// VuePress主题以及样式（这里也可以选择github主题）--VuePress主题代码呈黑色背景，github呈白色背景
import VMdEditor from '@kangc/v-md-editor';
// import VMdPreview from '@kangc/v-md-editor/lib/preview';
// import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import hljs from 'highlight.js';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import '@kangc/v-md-editor/lib/theme/style/github.css';

import VMdPreviewHtml from '@kangc/v-md-editor/lib/preview-html';
import '@kangc/v-md-editor/lib/style/preview-html.css';


URLSearchParams.prototype.appendIfExists = function (key, value) {
    if (value !== null && value !== undefined) {
        this.append(key, value)
    }
};
// 在 VMdEditor 中使用 github 主题，并传入 highlight.js
VMdEditor.use(githubTheme, {
    Hljs: hljs,
});

// 在 VMdPreview 中使用 vuepress 主题
// VMdPreview.use(vuepressTheme)

// 创建Vue应用实例
const app = createApp(App)

// 使用路由
app.use(router)

// 使用 VMdEditor 和 VMdPreview
app.use(VMdEditor)
// app.use(VMdPreview)


app.use(VMdPreviewHtml);

// 挂载应用
app.mount('#app')