<template>
    <!-- <NavigationBar/> -->

    <!-- <BlogFooter/> -->

    <NavigationBar />
    <div class="container">
        <div class="header">
            <h1>Unicorn博客</h1>
            <h2>山高路远，看世界，也看自己。</h2>
            <hr>
        </div>

        <div class="jobres">
            <br>
            <p style="margin-left: 15px; font-weight: bold;">关于我</p>
            <br>
            <span>2022-至今:Python开发、爬虫工程师</span>
            <br>
            <span>2021-2022:数据分析、算法工程师(CV方向)</span>
            <br>
            <br>
            <p>
                &nbsp; &nbsp;人生太短，江湖太大，不断摸索，找到适合自己的路，不断前进，让我们在成长路上不断祝福。
                <br>
                生活如同一场奇妙的旅程，每一刻都是一篇未知的篇章。在我的个人博客中，我用文字勾勒出这段旅程中的感悟，
                让生活的琐碎变得生动而贴切。
                <br>

                &nbsp;&nbsp; 在日常中，生活中的美好往往隐藏在平凡之中。或许是一杯清晨的咖啡，或许是窗外细雨的轻抚，
                每一个细微的瞬间都蕴含着深刻的感悟。捕捉这些微小而珍贵的瞬间，用文字将它们定格，
                让它们在博客中绽放。
                <br>


                &nbsp;&nbsp; 与此同时，我也在博客中分享自己在人生旅途中的思考。生活教会我坚韧，梦想教会我奋斗，挫折教会我成长。这些感悟既是对自己的反思，
                也是与读者分享的心灵碎片。我相信，每个人都有自己独特的人生观，而我的博客则是我用以表达这种独特观点的平台。
                <br>

                &nbsp;&nbsp;我追求的不仅是文字的简单堆砌，更是在每一篇博文中传递的一种情感，一份心灵的共鸣。通过用心写作，我希望读者在我的博客中能够找到共鸣，感受到生活的美好，激发内心的力量。

                我的博客是我对这段旅程的注脚，记录着成长的足迹，感悟的点滴。愿每一位读者在我的文字中，找到对生活的独特理解，与我一同品味这段奇妙而不可复制的旅程。
            </p>
            <br>
        </div>


        <div class="myblog">
            <p style="margin-left: 15px; font-weight: bold;">我的博客</p>
            <br>
            <p style="margin-left: 15px;">
                数据分析+Python爬虫+机器学习+Python开发+web后端开发(Django)+Nginx+Redis+Mysql+Docker
            </p>
            <br>
            <a href="https://blog.csdn.net/weixin_56175042" target="_blank"
                style="color: blueviolet;font-size: 15px;text-decoration: none;margin-left: 15px;">CSDN</a>
            <br>
        </div>

        <div class="mystash">
            <br>
            <p style="margin-left: 15px; font-weight: bold;">我的仓库</p>
            <br>
            <a href=""></a>
            <a href="https://github.com/kql789" target="_blank"
                style="color: blueviolet;font-size: 15px;text-decoration: none;margin-left: 15px;">Github</a>
            <a href="https://gitee.com/kql789" target="_blank"
                style="color: blueviolet;font-size: 15px;text-decoration: none;margin-left: 15px;">Gitee</a>

        </div>

        <div class="aboutweb">
            <br>
            <p style="margin-left: 15px; font-weight: bold;">关于本站</p>
            <br>
            <p style="margin-left: 15px;">
                书写是在寻找一种情绪抑或宣泄一种情绪。每个人都一个文字梦，
                总有一些想表达但却不太想被传播的想法，或许只是给自己，
                亦或只是想给自己最想被看到的人。
            <br>
            <br>
            本站声明，本站博客均由作者撰写，不涉及商业来往与交易，如需转载请保留原文链接及作者。
            </p>
            <br>
        </div>

        <div class="callme">
            <p style="margin-left: 15px; font-weight: bold;">联系我</p>
            <p style="font-family: 'Courier New', monospace;margin-left: 15px;">我的邮箱:kql789@126.com</p>
            <p style="margin-left: 15px;">我的微信:</p>
            <img src="../assets/weichat.jpg" alt="微信">
            <br>
        </div>
        <div class="source">
            <p style="margin-left: 15px; font-weight: bold;">资源共享</p>
            <br>
            <br>
            <a href="http://www.cxy521.com/" target="_blank"
                style="color: blueviolet;font-size: 15px;text-decoration: none;margin-left: 15px;">学习导航:http://www.cxy521.com/</a>
            <br>
            <br>
            <a href="https://dmdy10.vip/" target="_blank"
                style="color: blueviolet;font-size: 15px;text-decoration: none;margin-left: 15px;">影视资源:https://dmdy10.vip/</a>
            <br>
            <br>
            <p>
                <a href="https://mychat.kqlgpt.xyz" target="_blank"
                style="color: blueviolet;font-size: 15px;text-decoration: none;margin-left: 15px;">ChatGpt:https://mychat.kqlgpt.xyz</a>
                (联系作者获取gpt账号)
                <br>
            </p>

            <br>
            <a href="https://www.gequbao.com/" target="_blank"
                style="color: blueviolet;font-size: 15px;text-decoration: none;margin-left: 15px;">音乐网站:https://www.gequbao.com/</a>
            <br>
            <br>
            <a href="https://wallspic.com/" target="_blank"
                style="color: blueviolet;font-size: 15px;text-decoration: none;margin-left: 15px;">壁纸网站:https://wallspic.com/</a>
            <br>
            

        </div>
    </div>
    <div>

    </div>
    <!-- <MyComments/> -->
    <!-- <BlogFooter/> -->
</template>

<script>

import NavigationBar from './NavigationBar.vue';
import MyComments from '@/components/MyComments.vue';
import BlogFooter from '@/components/BlogFooter.vue';

export default {
    name: 'AboutMe',
    components: { BlogFooter, MyComments, NavigationBar }

}
</script>

<style>
.container {
    box-sizing: border-box;
    padding: 15px;
    max-width: 100%;
    margin: 0 auto;
    border-top: 50px solid #e6e6e6;
    border-right: 70px solid #e6e6e6;
    border-left: 70px solid #e6e6e6;
    height: 1500px;
}

.header h1 {
    margin-left: 10px;
    color: #515151;
    font-size: 18px;
    line-height: 1.846153846;
}

.header h2 {
    margin-left: 10px;
    color: #757575;
    font-size: 13px;
    line-height: 1.846153846;
}

.jobres span {
    margin-left: 15px;
    font-size: 14px;
    font-family: 'Microsoft YaHei', sans-serif;
}
.jobres p{
    margin-left: 15px;
    font-size: 13px;
    font-family: '宋体', sans-serif;
    line-height:2;

}
.callme img {
    width: 20%;
    height: auto;
    /* 自适应高度 */
    transition: transform 0.3s ease;
}

.callme img:hover {
    transform: scale(1.4);
    /* 图片放大到原来的1.2倍 */
}

@media (max-width: 768px) {
    .container {
        padding: 10px;
        border-top: 0;
        /* 移动端去掉顶部边框 */
        border-right: 0;
        /* 移动端去掉右侧边框 */
        border-left: 0;
        /* 移动端去掉左侧边框 */
    }

    .callme img {
        width: 50%
    }

    .callme img:hover {
        transform: scale(1.8);
        /* 图片放大到原来的1.2倍 */
    }
}
</style>

<style></style>