// 文件用于存放路由相关的文件
import { createWebHistory,createRouter } from "vue-router";
import myHome from "@/views/myHome.vue";
import ArticleCreate from "@/views/ArticleCreate.vue";
import UserCenter from "@/views/UserCenter.vue";
import ArticleEdit from "@/views/ArticleEdit.vue";
import ArticleTail from "@/views/ArticleTail.vue";
import LoginIn from "@/testcomponents/LoginIn.vue";
import AboutMe from "@/views/AboutMe.vue";

const routes = [
    {
        path:"/loginin",
        name:"LoginIn",
        component:LoginIn
    },
    {
        path:"/aboutme",
        name:"AboutMe",
        component:AboutMe
    },
    {
        path: "/",
        name: "myHome",
        component: myHome,
    },
    {
        path: "/user/:username",
        name: "UserCenter",
        component: UserCenter
    },
    {
        path: "/article/create",
        name: "ArticleCreate",
        component: ArticleCreate
    },
    {
        path: "/article/edit/:id",
        name: "ArticleEdit",
        component: ArticleEdit
    },
    {
        path:"/article/:id",
        name:"ArticleTail",
        component:ArticleTail
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;


// 列表 routes 定义了所有需要挂载到路由中的路径，成员为路径 url 、路径名和路径的 vue 对象。详情页面的动态路由采用冒号 :id 的形式来定义。
// 接着就用 createRouter() 创建 router。参数里的 history 定义具体的路由形式，
// createWebHashHistory() 为哈希模式（具体路径在 # 符号后面）；createWebHistory() 为 HTML5 模式
// （路径中没有丑陋的 # 符号），此为推荐模式，但是部署时需要额外的配置。