<template>
    <!--  br 标签给页脚留出位置  -->
    <br><br><br>
    
    <div id="footer">
        <div class="item">
            <p><a href="https://mychat.kqlgpt.xyz" target="_blank" style="text-decoration: none;font-size: 14px;">ChatGPT</a></p>
        </div>
        <div class="item">
            <a href="https://kqlgpt.xyz" target="_blank" style="color: black;font-size: 14px;text-decoration: none;" @click="goToHomePage">©Unicorn版权所有</a>
        </div>
        <div class="item">
            <p>
                <a href="https://beian.miit.gov.cn/" target="_blank" style="font-family:Microsoft YaHei;color: black;font-size: 10px;text-decoration: none;">鲁ICP备2023042160号-1</a>
            </p>
            <p style="display: flex; align-items: center;">
                <img style="height: 13px; width: 13px;margin-right: 3px;" src="../assets/beian.png">
                    <a class="banquan" 
                        style="font-family:Microsoft YaHei;color: black;font-size: 10px;text-decoration: none;"
                        href="https://beian.mps.gov.cn/#/query/webSearch?code=37010002000238" target="_blank">
                        鲁公网安备37010002000238
                    </a>
            </p>
        </div>

    </div>
    
</template>

<script>
    export default {
        name: 'BlogFooter',
        methods:{
            goToHomePage() {
            window.location.href = '/';
    },
    }

}
</script>

<style scoped>
#footer {
        position:fixed;
        display: flex;
        flex-wrap: nowrap;
        left: 0;
        bottom: 0;
        height: 50px;
        width: 100%;
        background: whitesmoke;
        font-weight: bold;
        justify-content: center;
    }
.item {
    flex: 1;
    margin-right: auto;
    display: flex;
    flex-direction: column; /* 竖直排列内容*/
    justify-content: center;
    align-items: center;
    height: 100%;
}
</style>