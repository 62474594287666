<template>

<router-view/>

</template>

<script>


export default {
  name:"App"

}

</script>

<style>


#app {
  font-family: Georgia, Arial, sans-serif;
  margin-left: 0px;
  margin-right: 0px;
    }
</style>
