<template>
    <NavigationBar />

    <div v-if="article !== null" class="grid-container">
        <div>
            <h1 id="title">{{ article.title }}</h1>
            <p id="subtitle">
                本文由 {{ article.author.username }} 发布于 {{ formatted_time(article.created) }}
                <span v-if="isSuperuser">
                    <router-link :to="{ name: 'ArticleEdit', params: { id: article.id } }">更新与删除</router-link>
                </span>
            </p>
            <!-- <v-md-preview :text="article.body_html"></v-md-preview> -->
            <v-md-preview-html :html="article.body_html" class="article-body"
                preview-class="vuepress-markdown-body"></v-md-preview-html>
        </div>

        <div class="mobile-hidden">
            <h3>目录</h3>
            <div v-html="article.toc_html" class="toc"></div>
        </div>



    </div>


    <MyComments :article="article" />

    <BlogFooter />
</template>



<script>
// import BlogHeader from '@/components/BlogHeader.vue'
import BlogFooter from '@/components/BlogFooter.vue'
import MyComments from '@/components/MyComments.vue'
import NavigationBar from '@/views/NavigationBar.vue';
import axios from 'axios';


export default {
    name: 'ArticleTail',
    components: { BlogFooter, MyComments, NavigationBar },
    data: function () {
        return {
            article: null
        }
    },
    mounted() {
        axios
            .get('/api/article/' + this.$route.params.id)
            .then(response => (this.article = response.data))
    },
    methods: {
        formatted_time: function (iso_date_string) {
            const date = new Date(iso_date_string);
            return date.toLocaleDateString()
        },
        handleCopyCodeSuccess(code) {
            console.log(code);
        },
    },
    computed: {
        isSuperuser() {
            return localStorage.getItem('isSuperuser.myblog') === 'true'
        }
    }
}

</script>

<style scoped>
.grid-container {
    margin-left: 20px;
    padding-left: 20px;
    display: grid;
    grid-template-columns: 3fr 1fr;
}

#title {
    text-align: center;
    font-size: x-large;
}

#subtitle {
    text-align: center;
    color: gray;
    font-size: small;
}



/* 媒体查询：当屏幕宽度小于某个值时，显示目录结构 */
@media (max-width: 768px) {

    .grid-container {
        grid-template-columns: 1fr;
        /* 单列布局 */
    }

    .mobile-hidden {
        display: none;
    }

    #title {
        text-align: left;
    }

    #subtitle {
        text-align: left;
    }

}
</style>

<style>
/* .article-body {
        margin-left: 30px;
        max-width: 100%;
    } */

.toc ul {
    list-style-type: none;
}

.toc a {
    color: gray;
}
</style>