<template>
    <div class="header">
        <ul>
            <li>
                <router-link to="/" class="login-link">Unicorn个人博客</router-link>
            </li>
            <div class="dropdown" style="float:left">
                <a href="#" class="dropbtn">文章列表</a>
                <div class="dropdown-content">
                    <router-link :to="{ name: 'myHome', query: { search: 'Python' } }">Python</router-link>
                    <router-link :to="{ name: 'myHome', query: { search: '数据分析' } }">数据分析</router-link>
                    <router-link :to="{ name: 'myHome', query: { search: 'SQL' } }">SQL</router-link>
                    <router-link :to="{ name: 'myHome', query: { search: 'Docker' } }">Docker</router-link>
                    <router-link :to="{ name: 'myHome', query: { search: '机器学习' } }">机器学习</router-link>
                    <router-link :to="{ name: 'myHome', query: { search: 'Django' } }">Django</router-link>
                    <router-link :to="{ name: 'myHome', query: { search: '开发' } }">开发</router-link>
                </div>
            </div>
            <li class="aboutme">
                <router-link :to="{ name: 'AboutMe' }">关于我</router-link>
            </li>

            <li class="register" style="float: right;">
                <router-link to="/loginin" class="login-link">注册</router-link>
            </li>
            <div style="float: right;">
                <div v-if="hasLogin">
                    <div class="dropdown">
                        <a href="#" class="dropbtn"> 欢迎 {{ username }}!</a>
                        <div class="dropdown-content">
                            <router-link :to="{ name: 'UserCenter', params: { username: username } }">用户中心</router-link>
                            <router-link :to="{ name: 'ArticleCreate' }" v-if="isSuperuser">发表文章</router-link>
                            <router-link to="/" v-on:click.prevent="logout()">登出</router-link>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <li class="myloginme">
                        <router-link to="/loginin" class="login-link">登录</router-link>
                    </li>

                </div>
            </div>

        </ul>
    </div>
</template>

<script>
import authorization from '@/utils/authorization';
export default {
    name: 'NavigationBar',
    data: function () {
        return {
            username: "",
            hasLogin: false,
            isSuperuser: JSON.parse(localStorage.getItem("isSuperuser.myblog"))

        };
    },
    mounted() {
        authorization().then((data) => ([this.hasLogin, this.username] = data));
    },
    methods: {
        logout() {
            localStorage.clear();
            window.location.reload(false);
        },
        refresh() {
            this.username = localStorage.getItem("username.myblog");
        },
        goToHomePage() {
            window.location.href = '/';
        },
    },
};

</script>

<style scoped>
* {
    margin: 0px;
    padding: 0px;
}
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #555;
}

li {
    float: left;
}

li a,
.dropbtn {
    display: inline-block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;

}

li a:hover,
.dropdown:hover {
    background-color: #111;
}

.dropdown {
    display: inline-block;
}

.dropdown-content {
    font-size: 13px;
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #f1f1f1
}

.dropdown:hover .dropdown-content {
    display: block;
}

@media screen and (max-width: 600px) {

    .login-link {
        display: block;

    }

    .dropdown {
        display: none;
    }

    .message {
        display: none;
    }

    .register {
        display: none;
    }
    .myloginme{
        display: block;
        float: left;
    }
    .aboutme{
        float: right;
        display: block;
    }
}
</style>

